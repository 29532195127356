<template>
  <div class="event-box">
    <div class="logo">
      <img :src="info.img" alt />
    </div>
    <div class="title">
      <span>{{ info.name }}</span>
    </div>
    <div class="introduce">
      <span>{{ info.synopsis }}</span>
    </div>

    <div class="switch-box">
      <van-tabs type="card">
        <van-tab title="详情">
          <div style="padding: 5px 0px" v-html="info.detail"></div>
        </van-tab>
        <van-tab title="相关课程">
          <div class="content-item" v-for="item in list" :key="item.id">
            <div class="left">
              <img :src="item.coverImg" alt />
            </div>
            <div class="right">
              <div>
                <span>{{ item.name }}</span>
                <!-- <span>{{ item.certName }}</span> -->
              </div>
              <div>{{ item.synopsis }}</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>


<script>
import { detailByTeacher, selectListByCertId } from "@/api/home"
import { Tab, Tabs } from 'vant';
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      info: {},
      list:[]
    }
  },
  created() {
    document.title = "证书详情";
    this.$store.commit("updateBarText", "证书详情");
    this.init()
  },
  methods: {
    init() {
      detailByTeacher({ id: this.$route.params.id }).then(res => {
        const { data } = res
        this.info = data.data
      })
      selectListByCertId({ certId: this.$route.params.id }).then(res => {
        const { data } = res
        this.list = data.data
      })
    }
  },
};
</script>

<style lang="less" scoped>
.event-box {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  .logo {
    margin-top: 70px;
    > img {
      width: 100%;
    }
  }
  .title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    color: #203152;
    font-size: 16px;
  }
  .introduce {
    // height: 40px;
    font-size: 13px;
    color: #4e4e4e;
  }
  .switch-box {
    padding: 20px 0px;
    // background: red;
    /deep/ .van-tabs__nav {
      margin: 0px;
    }

    .content-item {
      height: 100px;
      margin-top: 15px;
      display: flex;
      border-bottom: 1px solid #d6d6d6;
      .left {
        height: 80px;
        width: 100px;
        > img {
          height: 80px;
          width: 100px;
        }
      }
      .right {
        div {
          &:nth-of-type(1) {
            display: flex;
            width: 210px;
            justify-content: space-between;
            padding-top: 5px;
            padding-left: 15px;
            color: #203152;
            font-size: 16px;
          }
          &:nth-of-type(2) {
            padding-top: 5px;
            padding-left: 15px;
            color: #989898;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>